import React from 'react'
import BlogAllCardsSection from '../../components/page/blog-components/blog-all-page/blog-allcards-section/blog-allcards-section'
import Layout from "../../components/resuable/layout/layout";
import BlogAllHero from '../../components/page/blog-components/blog-all-page/blog-all-hero/blog-all-hero'
import Joinus from '../../components/resuable/joinus/joinus'

const All = () => {
  return (
    <Layout isBlogMenuActive={true}>
      <BlogAllHero />
      <BlogAllCardsSection />
      <Joinus />
    </Layout>
  )
}

export default All
